<template>
  <div class="container mx-auto px-4 py-2">
    <div class="columns">
      <div class="column is-full">
        <h2 class="mb-2">Select call to action to insert</h2>
        <div v-for="cta in content" class="column is-full" :key="cta.id">
          <button @click="insertVideoTinyMce(cta.id, cta.title)">{{cta.title}}</button>
        </div>
      </div>    
    </div>
    <div class="columns">
      <div class="column is-full">
        <nav class="pagination is-centered" role="navigation" aria-label="pagination">
        <Pagination v-if="content.length > 0" :page="page" :pages="pages" :itemCount="itemCount" :pageSize="pageSize" @next="next" @previous="previous" />
        </nav>
      </div>
    </div>
  
  </div>

</template>

<script>
import CtaService from '../../../services/admin/call_to_actions/service';
import Pagination from '../../../components/Pagination';

export default {
  components: {
    Pagination,
  },
  data: function() {
    return {
      content: '',
      page: 1,
      pages: 0,
      itemCount: 0,
      pageSize: 8
    }
  },
  methods: {
    fetchData() {
      CtaService.list(this.page, this.pageSize, this.searchQuery).then(
        response => {
          this.content = response.data.items;
          this.pages = response.data.pageCount;
          this.itemCount = response.data.itemCount
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    insertVideoTinyMce(id, title) {
      window.parent.postMessage({
          mceAction: 'sendUrl',
          data: {
            title: title,
            id: id
          }
      });
    },
    reset() {
      this.pages = 0;
      this.itemCount = 0;
      this.pages = 1;
    },
    clear() {
      this.searchQuery = null;
      this.reset();
      this.fetchData();
    },
    search() {
      this.reset();
      this.fetchData();
    },
    next() {
      this.page = this.page + 1;
      this.fetchData();
    },
    previous() {
      this.page = this.page - 1;
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },
  head () {
    return {
      title: 'CTA',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        }
      ]
    }
  }
};
</script>